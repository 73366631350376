<template>
    <form-view 
        v-slot="{ model, onSuccess, onBack }"
        title="Редактирование партнера"
        update
        url-update="/partner/update/:id"
        url-back="/partner"
        action-one="partner/one"
    >
        <partner-form :model="model" @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import PartnerForm from '@/components/forms/PartnerForm.vue'
export default {
    name: 'PartnerUpdate',
    components: { FormView, PartnerForm }
}
</script>