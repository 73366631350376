<template>
    <v-form>
        <v-alert
          v-if="!_.isNil(summaryError)"
          dense
          type="error"
        >
            {{ summaryError }}
        </v-alert>

        <v-switch
          v-model="form.active"
          label="Активность"
        ></v-switch>

        <v-text-field 
            v-model="$v.form.inn.$model"
            :error-messages="getErrors('form.inn', {
                pattern: 'Неверный ИНН'
            })"
            label="ИНН" 
            name="inn" 
            type="text" 
            placeholder=" "
            autofocus="autofocus" 
            color="light-green" 
        ></v-text-field>

        <v-text-field 
            v-model="$v.form.contacts.$model"
            :error-messages="getErrors('form.contacts')"
            label="Контактное лицо (ФИО)" 
            name="contacts" 
            type="text" 
            placeholder=" "
            color="light-green" 
        ></v-text-field>

        <v-text-field 
            v-model="$v.form.name.$model"
            :error-messages="getErrors('form.name')"
            label="Название" 
            name="name" 
            type="text" 
            placeholder=" "
            autofocus="autofocus" 
            color="light-green" 
        ></v-text-field>

        <v-text-field 
            v-model="$v.form.email.$model"
            :error-messages="getErrors('form.email')"
            autocomplete="new-email"
            label="E-mail" 
            name="email" 
            type="text" 
            placeholder=" "
            color="light-green" 
        ></v-text-field>

        <v-divider class="my-4" />

        <action-buttons 
            :waiting-save-and-back="waiting.save.back"
            :waiting-save-and-update="waiting.save.update"
            @back="back"
            @save="save"
        />
    </v-form>
</template>
<script>
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required, minLength, email } from 'vuelidate/lib/validators'
import ActionButtons from '@/components/crud/ActionButtons.vue'

export default {
    name: 'PartnerForm',
    components: { ActionButtons },
    props: {
        model: {
            type: Object
        }
    },
    mixins: [errorMixin, saveMixin, validationMixin],
    data() {
        return {
            storeModule: 'partner',
            form: {
                active: true,
                name: '',
                email: '',
                inn: '',
                contacts: ''
            }
        };
    },
    validations() {
        const STRING_FIELD_MIN_LENGTH = 2;
        return {
            form: {
                name: {
                    required,
                    minLength: minLength(STRING_FIELD_MIN_LENGTH)
                },
                email: {
                    required,
                    email
                },
                inn: {
                    required,
                    pattern: function(value) {
                        return /^([0-9]{10}|[0-9]{12})$/.test(value);
                    }
                },
                contacts: {
                    required,
                    minLength: minLength(STRING_FIELD_MIN_LENGTH)
                }
            }
        }
    }
}
</script>
<style scoped lang="scss">

</style>