<template>
    <v-row justify="start" align="center">
        <v-col class="col-auto">
            <v-btn 
                dark 
                outlined 
                :color="$const.color.primary" 
                small
                @click.prevent.stop="back"
            >
                <v-icon left dark>
                    mdi-arrow-left
                </v-icon>
                Назад к списку
            </v-btn>
        </v-col>
        <v-spacer />
        <v-col class="col-auto">
            <v-btn 
                dark 
                :color="$const.color.primary"
                small
                :disabled="anyWaiting" 
                :loading="waitingSaveAndBack" 
                @click.prevent.stop="save(true)"
            >
                Сохранить и вернуться к списку
            </v-btn>
        </v-col>
        <v-col class="col-auto">
            <v-btn 
                v-if="noSaveAndUpdate !== true"
                dark 
                :color="$const.color.primary"
                small
                :disabled="anyWaiting" 
                :loading="waitingSaveAndUpdate" 
                @click.prevent.stop="save(false)"
            >
                Сохранить и продолжить редактирование
            </v-btn>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: 'ActionButtons',
    props: {
        noSaveAndUpdate: Boolean,
        waitingSaveAndBack: Boolean,
        waitingSaveAndUpdate: Boolean
    },
    data() {
        return {}
    },
    computed: {
        anyWaiting() {
            return this.waitingSaveAndBack || this.waitingSaveAndUpdate;
        }
    },
    methods: {
        back() {
            return this.$emit('back', 1);
        },
        save(back) {
            return this.$emit('save', back);
        },
    }
}
</script>